function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.symbol.to-string-tag.js";
import "core-js/modules/es.json.to-string-tag.js";
import "core-js/modules/es.math.to-string-tag.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// A shim for Promise.withResolvers. Once browser support is widespread, we can
// remove this.
export function promiseWithResolvers() {
  var resolve;
  var reject;
  var promise = new Promise(function (res, rej) {
    resolve = res;
    reject = rej;
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return {
    promise: promise,
    resolve: resolve,
    reject: reject
  };
}
export function createInitStatus() {
  var _ref;
  var _promiseWithResolvers = promiseWithResolvers(),
    promise = _promiseWithResolvers.promise,
    resolve = _promiseWithResolvers.resolve;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  var _resolved = false;
  return _ref = {
    promise: promise,
    resolve: function (_resolve) {
      function resolve(_x) {
        return _resolve.apply(this, arguments);
      }
      resolve.toString = function () {
        return _resolve.toString();
      };
      return resolve;
    }(function (x) {
      _resolved = true;
      resolve(x);
    }),
    then: promise.then.bind(promise),
    catch: promise.catch.bind(promise),
    finally: promise.finally.bind(promise)
  }, _defineProperty(_ref, Symbol.toStringTag, "InitStatus"), _defineProperty(_ref, "resolved", function resolved() {
    return _resolved;
  }), _ref;
}